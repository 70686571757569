.App {
  margin: 0px;
}

:root {
  overscroll-behavior: none;
}

html,
body {
  overflow-x: hidden;
}
body {
  position: relative;
}
