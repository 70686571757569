.container-custom {
  background: #040d12;
  margin: 0px;
  width: 100%;
  padding-bottom: 100px;
}

h1 {
  margin-top: 0px;
  color: white !important;
  display: flex;
  justify-content: center;
}

h2 {
  margin-top: 0px;
  padding: 100px 0 20px 0;
  color: white !important;
  display: flex;
  justify-content: center;
}

.img-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin: 0 40px 0 40px;
}

img {
  width: 350px;
  height: 450px;
}
