.home-container {
  background-color: #040d12;
  color: white;
  width: 100%;
}

.home-headline {
  background-color: #040d12;
  margin: 0 !important;
}

.home-text-container {
  display: flex;
  gap: 100px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  overflow: auto;
  padding: 80px 20px 100px;
  flex-wrap: wrap;
  background-color: #040d12;
}

.home-img-container > img {
  border: 3px solid white;
  border-radius: 5px;
  height: auto;
  width: 500px;
}

.home-img-container {
  max-width: 100%;
}

.responsive,
.home-about-me-text {
  max-width: 100%;
  font-size: large;
  word-break: break-word;
}

.about-me-text-bigger {
  font-size: 1.8em;
  font-weight: 500;
  color: #93b1a6;
}

.about-me-text-highlighted {
  color: #93b1a6;
  font-weight: 500;
}
