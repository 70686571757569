.carousel-caption {
  top: 0;
}

.text-carousel-big {
  font-size: 1.8em;
  font-weight: 500;
}

.text-carousel-smaller {
  font-size: 1.5em;
  font-weight: 500;
}
